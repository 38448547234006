import path from 'path';

import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  cacheControl,
  filterMarkets,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { getApi, markets } from '@hultafors/solidgear/api';
import { createMeta } from '@hultafors/solidgear/helpers';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/solidgear/types';

const Page = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Page),
);

const ContentArea = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.ContentArea),
);
// Needed for standalone build, for some reason
path.resolve('./next.config.js');

interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

const LandingPage: NextPage<LandingPageProps> = ({
  content,
  settings,
  hrefLanguages,
}) => {
  // TODO createMeta from global lib/helpers

  return (
    <Page
      metadata={createMeta(content?.seo, settings?.language.lang)}
      hrefLanguages={hrefLanguages}
      testId="landing-page"
    >
      {content?.contentArea && <ContentArea content={content.contentArea} />}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);
    const {
      data: { landingPage: content, ...globalFields },
    } = await dato.getLandingPage();

    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'SOLIDGEAR'),
      content?.pageLocales,
    );

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        settings,
        ...(globalFields as GlobalFields),
        content,
        hrefLanguages,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export default LandingPage;
